import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk with error handling
export const userLogin = createAsyncThunk('/auth/adminlogin', async (payload, { rejectWithValue }) => {
    try {
        const response = await axios.post('/auth/adminlogin', payload);
        return response.data;
    } catch (error) {
        // Check if the error response exists and return a specific error message
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        isLoading: false,
        user: null,
        error: null
    },
    reducers: {
        // Placeholder for potential future reducers
    },
    extraReducers: (builder) => {
        builder
            .addCase(userLogin.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(userLogin.fulfilled, (state, action) => {
                state.user = action.payload;
                state.isLoading = false;
                state.error = null;
            })
            .addCase(userLogin.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload.message || 'An error occurred';
            });
    }
});

export default userSlice.reducer;
