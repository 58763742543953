import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getUsersData = createAsyncThunk(
  "/users/content",
  async () => {
    const token = localStorage.getItem("token");

    const response = await axios.get("/user", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
);

export const getPendingUsersData = createAsyncThunk(
  "/pendingusers/content",
  async () => {
    const token = localStorage.getItem("token");

    const response = await axios.get("/user?pendingUsers=true", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { pendingUsers: true }
    });
    return response.data;
  }
);

export const GetUser = createAsyncThunk(
  "/user/content",
  async (id) => {
    const token = localStorage.getItem("token");

    const response = await axios.get(`/user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    return response.data;
  }
);

export const UpdateUser = createAsyncThunk(
  "/user/update",
  async (obj) => {
    const token = localStorage.getItem("token");

    const response = await axios.patch(`/user/update/${obj._id}`, obj, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    isLoading: false,
    users: [],
    user: {},
    pendingUsers: []
  },
  reducers: {
  },

  extraReducers: {
    [getUsersData.pending]: (state) => {
      state.isLoading = true;
    },
    [getUsersData.fulfilled]: (state, action) => {
      state.users = action.payload?.data;
      state.isLoading = false;
    },
    [getUsersData.rejected]: (state) => {
      state.isLoading = false;
    },
    [getPendingUsersData.pending]: (state) => {
      state.isLoading = true;
    },
    [getPendingUsersData.fulfilled]: (state, action) => {
      state.pendingUsers = action.payload?.data;
      state.isLoading = false;
    },
    [getPendingUsersData.rejected]: (state) => {
      state.isLoading = false;
    },
    [GetUser.pending]: (state) => {
      state.isLoading = true;
    },
    [GetUser.fulfilled]: (state, action) => {
      state.user = action.payload?.data;
      state.isLoading = false;
    },
    [GetUser.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

// export const { addNewLead, deleteLead } = usersSlice.actions

export default usersSlice.reducer;
