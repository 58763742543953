import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getComplaints = createAsyncThunk(
  "/complaints/content",
  async () => {
    const token = localStorage.getItem("token");

    const response = await axios.get("/complaint", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
);

export const complaintsSlice = createSlice({
  name: "complaints",
  initialState: {
    isLoading: false,
    complaints: []
  },
  reducers: {
  },

  extraReducers: {
    [getComplaints.pending]: (state) => {
      state.isLoading = true;
    },
    [getComplaints.fulfilled]: (state, action) => {
      state.complaints = action.payload?.data;
      state.isLoading = false;
    },
    [getComplaints.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

// export const { addNewLead, deleteLead } = complaintsSlice.actions

export default complaintsSlice.reducer;
