import { configureStore } from '@reduxjs/toolkit'
import headerSlice from '../features/common/headerSlice'
import modalSlice from '../features/common/modalSlice'
import rightDrawerSlice from '../features/common/rightDrawerSlice'
import usersSlice from '../features/users/usersSlice'
import userSlice from '../features/user/userSlice'
import jobsSlice from '../features/jobs/jobsSlice'
import complaintsSlice from '../features/complaints/complaintsSlice'

const combinedReducer = {
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  users: usersSlice,
  jobs: jobsSlice,
  user: userSlice,
  complaint: complaintsSlice
}

export default configureStore({
  reducer: combinedReducer
})